/*
 * @Author: 曾开胜
 * @Date: 2022-10-12 20:04:36
 * @LastEditors: 曾开胜
 * @LastEditTime: 2022-10-14 09:55:23
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'home',
      component: resolve => require(['@/views/home/home'], resolve),
      meta: {
        title: '单品链接汇总',
        keepAlive: true
      }
    }, {
      path: '/productDetail',
      name: 'productDetail',
      component: resolve => require(['@/views/productDetail/productDetail'], resolve)
    }

  ]
})

router.beforeEach((to, from, next) => {
  // console.log(from)
  if (to.meta.title) {
    // document.title = to.meta.title
    document.title = to.meta.title
  }
  next()
});

export default router
