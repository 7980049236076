/*
 * @Author: 曾开胜
 * @Date: 2022-10-12 20:04:36
 * @LastEditors: 曾开胜
 * @LastEditTime: 2022-10-14 10:40:37
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
require('swiper/dist/css/swiper.css')
// import
import VueAwesomeSwiper from 'vue-awesome-swiper'
// mount with global
Vue.use(VueAwesomeSwiper)
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
import MetaInfo from 'vue-meta-info'
import waterfall from "vue-waterfall2";

Vue.use(waterfall);

Vue.config.productionTip = false

Vue.use(MetaInfo)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
