import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({

  state: {
    // 存储token
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    userInfo: localStorage.getItem('userInfo') ? localStorage.getItem('userInfo') : ''
  },

  mutations: {
    // 修改token，并将token存入localStorage
    setToken(state, token) {
      state.Authorization = token;
      localStorage.setItem('Authorization', token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      localStorage.setItem('userInfo', userInfo);
    }
  }
});

export default store;

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
